
import Vue, { VueConstructor } from "vue"
import ListParagon from "@/components/ListParagon.vue"
import { state } from "@/plugins/state"
import { EventBus } from "@/plugins/eventBus"
import FilesMixin from "@/mixins/FilesMixin.vue"
import dateFormatMixin from "@/mixins/DateFormatMixin.vue"
import ClientsFormatter from "@/components/workers/ClientsFormatter.vue"
import DateTimePicker from '@/components/DateTimePicker.vue'
import PositionsAutocompleteMixin from "@/mixins/PositionsAutocompleteMixin.vue"
import format from "date-fns/format"
import SendCVDialog from "@/components/workers/SendCVDialog.vue"
import SendSMSDialog from "@/components/workers/SendSMSDialog.vue"

export default (Vue as VueConstructor<Vue & InstanceType<typeof dateFormatMixin> & InstanceType<typeof FilesMixin> & InstanceType<typeof PositionsAutocompleteMixin>>).extend({
  name: "WorkerList",
  mixins: [
    FilesMixin,
    dateFormatMixin,
    PositionsAutocompleteMixin,
  ],
  components: {
    ListParagon,
    ClientsFormatter,
    DateTimePicker,
    SendCVDialog,
    SendSMSDialog
  },
  data () {
    return {
      disabledSubmit: false,
      adaptTimeData: ':00',
      resetSelection: false,
      totalWorkers: 0,
      // show modal
      showSendCV: false,
      showSendSMS: false,
      availabilityRequestDate: {dateObject: new Date(), dateString: "", timeString: ""},
      dialog: false,
      titleModal: "alerts_details",
      major: true,
      data: null,
      textAreaData: "",
      states: [
        {"value":"web", name: this.$vuetify.lang.t('$vuetify.worker_states.web')},
        {"value":"candidate", name: this.$vuetify.lang.t('$vuetify.worker_states.candidate')},
        {"value":"employee", name: this.$vuetify.lang.t('$vuetify.worker_states.employee')},
        {"value":"pre-registered", name: this.$vuetify.lang.t('$vuetify.worker_states.pre_registered')},
        {"value":"supplements OK", name: this.$vuetify.lang.t('$vuetify.worker_states.supplement_ok')},
      ],
      updatableProperties: {
        state: null,
        add_comment: null,
        add_positions: null,
        remove_positions: null,
        deleted_at: null,
        availability_request: null,
      } as { [key: string]: any },
      propertiesToUpdate: null as null|{
        url: string,
        success: string,
        data: {[key: string]: string | number},
        action: string
      },
      openModal: false,
      modalName: "add_comment",
      selected: [] as string[],
      workerTranslation: 'worker',
      expanded: false,
      headers: [
        {
            text: this.$vuetify.lang.t("$vuetify.card"),
            sortable: true,
            value: 'card'
        },
        {
            text: this.$vuetify.lang.t("$vuetify.favorite_jobs"),
            sortable: true,
            value: 'favorite_jobs',
            sortText: this.$vuetify.lang.t("$vuetify.favorite_jobs_total")
        },
        {
            text: this.$vuetify.lang.t("$vuetify.contact_details"),
            sortable: true,
            value: 'mobile_phone_number',
            sortText: this.$vuetify.lang.t("$vuetify.mobile_phone_number")
        },
        {
            text: this.$vuetify.lang.t("$vuetify.availabilities"),
            sortable: true,
            value: 'status_updated_at',
            sortText: this.$vuetify.lang.t("$vuetify.status_updated_at")
        },
        {
            text: this.$vuetify.lang.t("$vuetify.recruitment"),
            sortable: true,
            value:'follow_up',
            sortText: this.$vuetify.lang.t("$vuetify.recruitment_total")

        },
        {
            text: this.$vuetify.lang.t("$vuetify.SMS_interaction"),
            sortable: true,
            value: "sms_history",
            width: 320,
            sortText: this.$vuetify.lang.t("$vuetify.SMS_interaction_last_date")
        },
        {
            text: this.$vuetify.lang.t("$vuetify.comments"),
            sortable: true,
            value: "last_comment",
            sortText: this.$vuetify.lang.t("$vuetify.comment_last_date")

        },
      ],
      headersSplitPane: [
        {
          text: this.$vuetify.lang.t("$vuetify.names"),
          sortable: true,
          value: 'names'
        }
      ],
      filters: [
        {label: 'names', type: 'text-field'},
        {label: 'email', type: 'text-field'},
        {label: 'mobile_phone_number', type: 'text-field'},
        {
          label: 'state',
          type: 'select',
          items:[
            {text: this.$vuetify.lang.t("$vuetify.web"), value: "web"},
            {text: this.$vuetify.lang.t("$vuetify.candidate"), value: "candidate"},
            {text: this.$vuetify.lang.t("$vuetify.employee"), value: "employee"},
            {text: this.$vuetify.lang.t("$vuetify.pre-registered"), value: "pre-registered"},
            {text: this.$vuetify.lang.t("$vuetify.supplements OK"), value: "supplements OK"}
          ],
          multiple: true
        },
        {
          label: 'status',
          text: this.$vuetify.lang.t("$vuetify.availabilities"),
          type: 'select',
          items:[
            {text: this.$vuetify.lang.t("$vuetify.unavailable"), value: "unavailable"},
            {text: this.$vuetify.lang.t("$vuetify.pending"), value: "pending"},
            {text: this.$vuetify.lang.t("$vuetify.available"), value: "available"},
            {text: this.$vuetify.lang.t("$vuetify.contract"), value: "contract"},
            {text: this.$vuetify.lang.t("$vuetify.unknown"), value: "unknown"}
          ],
          multiple: true
        },
        {label: 'positions', prependValue: 'true:', type: 'autocomplete', url:'/v1/clients/positions', text: 'name', value: 'position_id', search: 'name'},
        {label: 'favorite_jobs', type: 'text-field'},
        {label: 'postal_city_code', type: 'text-field'},
        {
          label: 'transportation_means',
          type: 'select',
          items:[
            {text: this.$vuetify.lang.t("$vuetify.car"), value: "car"},
            {text: this.$vuetify.lang.t("$vuetify.motorbike"), value: "motorbike"},
            {text: this.$vuetify.lang.t("$vuetify.bike_scooter"), value: "bike_scooter"},
            {text: this.$vuetify.lang.t("$vuetify.mass_transit"), value: "mass_transit"},
            {text: this.$vuetify.lang.t("$vuetify.moped"), value: "moped"},
            {text: this.$vuetify.lang.t("$vuetify.walk"), value: "walk"},
            {text: this.$vuetify.lang.t("$vuetify.carpool"), value: "carpool"}
          ],
          multiple: true
        },
        {label: 'comment', type: 'text-field'},
        {
          label:'cv',
          type: 'toggle',
          items: [
            {text: this.$vuetify.lang.t("$vuetify.with"), value: true},
            {text: this.$vuetify.lang.t("$vuetify.without"), value: false}
          ]
        }
      ]
    }
  },
  computed: {
    url: function(): string {
      let agencyParameter = ""
      if (state.agency !== null) {
        agencyParameter = `&agency_id=${state.agency}`
      }
      return `/v1/workers?deleted_at=false${agencyParameter}&fields=
birth_date,
clients,
cv,
date_last_comment,
email,
favorite_jobs,
first_name,
id,
last_comment,
last_name,
mobile_phone_number,
postal_city,
postal_code,
profile_picture,
sms_history,
state,
status,
status_updated_at,
transportation_means`
    },
    dataForUpdate: function (): any {
      // this method build a body to send to api
      let body :{[key: string]: string|number|string[]} = {}
      if (['add_comment','send_sms','send_cv'].includes(this.modalName)) {
        let ids = []
        for (const item of this.selected) {
          ids.push(item)
        }
        body['id'] = ids
        body[this.modalName] = this.updatableProperties[this.modalName] as string
      } else {
        for (const swId of this.selected) {
          for (const [key, value] of Object.entries(this.updatableProperties)) {
            if (value) {
              let data: {[key: string]: typeof value} = {}
              data[key] = value
              body[swId] = data as string|string[]|number
            }
          }
        }
      }
      return this.cleanBody(body)
    }
  },
  watch: {
    availabilityRequestDate() {
      this.updatableProperties.availability_request = `${this.availabilityRequestDate.dateString} ${this.availabilityRequestDate.timeString}${this.adaptTimeData}`
      this.buildUpdateData(
        '/v1/workers/action',
        'availability_request_created',
        "post"
      )
    },
    async openModal() {
      // Start - BUGFIX
      this.updatableProperties = { //  reset API calls potential data
        state: null,
        add_comment: null,
        add_positions: null,
        remove_positions: null,
        deleted_at: null,
        availability_request: null,
        send_sms: null,
        send_cv: null
      }
      // End - BUGFIX reset API calls potential data
      this.propertiesToUpdate = {url: "", success: "", data: {}, action: 'put'}
      this.totalWorkers = this.selected.length
      this.resetData()
    },
    selectedPositions() {
      this.updatableProperties[this.modalName] = this.selectedPositions as number[]
      // with an @change on the autocomplete, the data sent to ListParagon was always one value behind
      // ex: positions 1,2,3 were selected, the paragon would only get positions 1,2
      // since this data is already watched, I've put the buildUpdateData here
      this.buildUpdateData(
        '/v1/workers',
        `success_${this.modalName}`,
        'put'
      )
    },
    textAreaData() {
      this.updatableProperties[this.modalName] = this.textAreaData
    },
    updatableProperties: {
      handler() {
        let property  = this.modalName
        if (this.updatableProperties[this.labelBuilder()]) {
          property = this.labelBuilder()
        }
        if (this.updatableProperties[property] !== null && this.updatableProperties[property] !== undefined) {
          this.checkDisableSubmit(property)
        }
      },
      deep: true
    }
  },
  methods: {
    redirectToSplit(id: number) {
      (this.$refs.list as InstanceType<typeof ListParagon>).redirectToSplitPane(id)
    },
    resetData() {
      this.propertiesToUpdate = {url: "", success: "", data: {}, action: 'put'}
      this.textAreaData =''
      let date = new Date()
      switch (this.modalName) {
        case "archive":
          this.updatableProperties.deleted_at = format(date, "yyyy-MM-dd HH:mm:ss")
          this.buildUpdateData(
            '/v1/workers',
            'success_archive',
            'put'
          )
          break
        case "availability_request":
          this.updatableProperties[this.modalName] = format(date, "yyyy-MM-dd HH:mm:ss")
          this.buildUpdateData(
          '/v1/workers/action',
          'availability_request_created',
          "post"
          )
          break;
        case "add_positions":
        case "remove_positions":
          Vue.set(this.updatableProperties, this.modalName, null)
          this.selectedPositions = null
          this.buildUpdateData(
            '/v1/workers',
            `success_${this.modalName}`,
            'put'
          )
          break
        default:
          break
      }
    },
    checkDisableSubmit(property: string){
      this.disabledSubmit = this.updatableProperties[property].length === 0
    },
    toggleCardState() {
      this.expanded = !this.expanded
    },
    redirectTo(name: string) {
      EventBus.$emit("availabilityWorker", name)
    },
    openData(titleModal: string, data: any, major = true) {
      this.dialog = true
      this.major = major
      this.data = data
      this.titleModal = titleModal
    },
    checkLogo(name: string) {
      switch (name) {
        case 'bike_scooter':
          return 'bike'
        case 'mass_transit' :
          return 'bus'
        case 'carpool' :
          return 'car-2-plus'
        default:
          return name
      }
    },
    isMajor(date: string | undefined) {
      if (typeof date === 'undefined')
       return true
      return (Date.now() - new Date(date).getTime()) / 1000/60/60/24/365.25 >= 18
    },
    checkAlerts(alerts: Array<string>|undefined) {
      return alerts === undefined || alerts.length === 0
    },
    buildUpdateData(url: string, successText: string, action: string) {
      let body :{[key: string]: any} = {}
      if (action === "put") {
        body = this.buildPutData(body)
      } else if (action === "post") {
        body = this.buildPostData(body)
      }
      this.propertiesToUpdate = {url: url, success: successText, data: body, action: action}
    },
    buildPostData(body: {[key: string]: any}) {
      for (const [key, value] of Object.entries(this.updatableProperties)) {
        if (value) {
          body[key] = value
        }
      }
      body["id"] = this.selected
      return body
    },
    buildPutData(body: {[key: string]: any}) {
      for (const swId of this.selected) {
        for (const [key, value] of Object.entries(this.updatableProperties)) {
          if (value) {
            body[swId] = {
              [key]: value
            }
          }
        }
      }
      return body
    },
    getNbSelected(n: string[]) {
      this.selected = n
      if (this.selected.length > 1) {
        this.workerTranslation = 'workers'
      } else {
        this.workerTranslation = 'worker'
      }
    },
    resetModalFields() {
      if (this.modalName === 'archive') {
        this.resetSelection = !this.resetSelection
      }
    },
    labelBuilder() {
      //return second part of splitted string like update_state => state
      return this.modalName.split('_')[1]
    },
    cleanBody(body: any) {
      // check empty property to clean body before sending to API
      for (let property in body) {
        if (typeof body[property] === "object") {
          for (let deepProperty in body[property]) {
            if (body[property][deepProperty] === null) {
              delete body[property][deepProperty]
            }
          }
        } else if (body[property] === null) {
            delete body[property]
        }
      }
      return body
    }
  }
})

